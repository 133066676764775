import ApiRoutes from "../../../routes/routeConstants/apiRoutes";
import axiosInstance from "../../../interceptor/axiosInstance";
import { deserialize, serialize } from "serializr";
import Notification from "../../../shared/components/Notification";
import { NotificationTypes } from "../../../enums/notificationTypes";
import { CaseDetail } from "../../../models/CaseDetail/caseDetail.model";
import { convertJSONToFormData } from "../../../shared/utils/dataFormatConverter";
import { CaseFilterParams } from "../../../models/CaseFilterParams/caseFilterParams.model";
import { PaginationMeta } from "../../../models/PaginationMeta/paginationMeta.model";

export default class ScanCenterCaseService {
  static fetchScanCenterCases(
    filterParams: CaseFilterParams,
    onSuccess: Function,
    onError: Function,
    onFinal: () => void
  ) {
    const params = serialize(filterParams);
    axiosInstance
      .get(ApiRoutes.SCAN_CENTER_CASES, {
        params: {
          ...params,
          page: filterParams?.page ?? 1,
          per_page: 10,
        },
      })
      .then((response) => {
        const scanCenterCases = deserialize(
          CaseDetail,
          response.data["case_details"]
        );
        const paginationMeta = deserialize(
          PaginationMeta,
          response.data["meta"]
        );
        onSuccess(scanCenterCases, paginationMeta);
      })
      .catch((error) => {
        onError();
      })
      .finally(() => {
        onFinal();
      });
  }

  static showScanCenterCase(
    scanCenterCaseId: number,
    onSuccess: Function,
    onError: Function,
    onFinal: () => void
  ) {
    const API_URL = ApiRoutes.SCAN_CENTER_CASES + "/" + scanCenterCaseId;
    axiosInstance
      .get(API_URL)
      .then((response) => {
        const scanCenterCase = deserialize(
          CaseDetail,
          response.data["case_detail"]
        );
        onSuccess(scanCenterCase);
      })
      .catch((error) => {
        Notification({
          message: error,
          type: NotificationTypes.ERROR,
        });
        onError();
      })
      .finally(() => {
        onFinal();
      });
  }

  static createScanCenterCase(
    scanCenterCase: CaseDetail,
    onSuccess: Function,
    onError: Function,
    onFinal: () => void
  ) {
    const scanCenterCaseJSON = { case_detail: serialize(scanCenterCase) };
    const caseForm = convertJSONToFormData(scanCenterCaseJSON);
    axiosInstance
      .post(ApiRoutes.SCAN_CENTER_CASES, caseForm)
      .then((response) => {
        Notification({
          message: "Case created",
          type: NotificationTypes.SUCCESS,
        });
        const scanCenterCase = deserialize(
          CaseDetail,
          response.data["case_detail"]
        );
        onSuccess(scanCenterCase);
      })
      .catch((error) => {
        onError();
      })
      .finally(() => {
        onFinal();
      });
  }

  static updateScanCenterCase(
    scanCenterCase: CaseDetail,
    onSuccess: Function,
    onError: Function,
    onFinal: () => void
  ) {
    const API_URL = ApiRoutes.SCAN_CENTER_CASES + "/" + scanCenterCase.id;
    const scanCenterCaseJSON = { case_detail: serialize(scanCenterCase) };
    const caseForm = convertJSONToFormData(scanCenterCaseJSON);
    axiosInstance
      .put(API_URL, caseForm)
      .then((response) => {
        Notification({
          message: "Case updated",
          type: NotificationTypes.SUCCESS,
        });
        const scanCenterCase = deserialize(
          CaseDetail,
          response.data["case_detail"]
        );
        onSuccess(scanCenterCase);
      })
      .catch((error) => {
        onError();
      })
      .finally(() => {
        onFinal();
      });
  }

  static getCasePresignedURL(
    caseDetailId: number,
    onSuccess: Function,
    onError: Function,
    onFinal: () => void
  ) {
    const API_URL = ApiRoutes.SCAN_CENTER_PRESIGNED_URL.replace(
      ":caseDetailId",
      caseDetailId.toString()
    );
    axiosInstance
      .get(API_URL)
      .then((response) => {
        onSuccess({
          url: response.data.url,
          fileName: response.data.file_name,
        });
      })
      .catch((error) => {
        onError();
      })
      .finally(() => {
        onFinal();
      });
  }

  static getCaseAttachmentPresignedURL(caseDetailId: number, fileName: string) {
    const API_URL =
      ApiRoutes.SCAN_CENTER_ATTACHMENT_PRESIGNED_URL.replace(
        ":caseDetailId",
        caseDetailId.toString()
      ) +
      "?file_name=" +
      fileName;
    return axiosInstance.get(API_URL);
  }

  static deleteCaseAttachment(caseDetailId: number, imagePath: string) {
    const API_URL = ApiRoutes.SCAN_CENTER_ATTACHMENT_DELETE.replace(
      ":caseDetailId",
      caseDetailId.toString()
    );
    return axiosInstance.put(API_URL, {
      image_url: imagePath,
    });
  }

  static updateFileUploadStatus(
    caseDetailId: number,
    gcpKey: string,
    onSuccess: Function,
    onError: Function,
    onFinal: () => void
  ) {
    const API_URL = ApiRoutes.SCAN_CENTER_UPDATE_FILE.replace(
      ":caseDetailId",
      caseDetailId.toString()
    );
    axiosInstance
      .put(API_URL, {
        gcp_key: gcpKey,
      })
      .then((response) => {
        Notification({
          message: "File uploaded successfully!",
          type: NotificationTypes.SUCCESS,
        });
        onSuccess();
      })
      .catch((error) => {
        onError();
      })
      .finally(() => {
        onFinal();
      });
  }

  static uploadScanCenterCaseFile(
    caseDetailId: number,
    zipFile: File,
    onSuccess: Function,
    onError: Function,
    onFinal: () => void
  ) {
    const API_URL =
      ApiRoutes.SCAN_CENTER_CASES + "/" + caseDetailId + "/upload";
    const scanCenterCaseJSON = { case_detail: { zip_file: zipFile } };
    const caseForm = convertJSONToFormData(scanCenterCaseJSON);
    Notification({
      message:
        "File upload started. Please do not refresh the page until the upload starts",
      type: NotificationTypes.SUCCESS,
      key: caseDetailId.toString(),
      duration: 100,
    });
    axiosInstance
      .put(API_URL, caseForm)
      .then((response) => {
        const scanCenterCase = deserialize(
          CaseDetail,
          response.data["case_detail"]
        );
        onSuccess(scanCenterCase);
      })
      .catch((error) => {
        onError();
      })
      .finally(() => {
        onFinal();
      });
  }

  static deleteScanCenterCase(
    scanCenterCaseId: number,
    onSuccess: Function,
    onError: Function,
    onFinal: () => void
  ) {
    const API_URL = ApiRoutes.SCAN_CENTER_CASES + "/" + scanCenterCaseId;
    axiosInstance
      .delete(API_URL)
      .then((response) => {
        onSuccess();
      })
      .catch((error) => {
        onError();
      })
      .finally(() => {
        onFinal();
      });
  }
}
